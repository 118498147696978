import React from 'react'

import Header from './header.js'
import Footer from "./footer.js"
import CookiesPopUp from './cookies'

const Layout=(props) => {
    return (
        <div className="bg">
            <div className="bg1">
                <div className="light">
                    <Header />
                    {props.children}
                    <CookiesPopUp/>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Layout
