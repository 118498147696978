import React from 'react';
import CookieConsent from "react-cookie-consent";
import {Link} from 'gatsby'
import './cookies.scss';

const CookiesPopUp=() => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Rozumiem"
            cookieName="cookiesBanner"
            SameSite="lax"
            cookieSecurity={true}
            containerClasses="cookies"
            buttonClasses="cookie_button"
            expires={150}
        >
            Ta strona używa ciasteczek (
            <Link to="/polityka-cookies"
                className="cookies_link"
                aria-label="przeczytaj co dokładnie mogą zwierać nasze ciasteczka"
            >
                cookies
                </Link>
                ), dzięki którym nasz serwis może działać lepiej.{" "}

            <a
                href="https://wszystkoociasteczkach.pl"
                rel="noreferrer noopener"
                className="link cookies_link"
                target="_blank"
                aria-label="dowiedz się więcej czym są ciasteczka"
            >Dowiedz się więcej</a>
        </CookieConsent>
    )
}

export default CookiesPopUp
