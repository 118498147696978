import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import { TelephoneIcon } from "./../images/svg/TelepgoneIcon"
import "./../styles/reset.css"
import "./../styles/skeleton.css"
import "./../styles/superfish.css"
import "./../styles/style.css"
import "./header.scss"

const Header = () => {
  return (
    <header className="header">
      <Helmet>
        <html lang="pl" />
        <meta name="robots" content="index,follow" />
        <meta name="rating" content="general" />
      </Helmet>

      <div className="main">
        <div className="container_12">
          <div className="grid_12">
            <div className="tel_container">
              <a
                  href="tel:511435200"
                  className="tel"
                  aria-label="zadzwoń do nas"
                >
                  <TelephoneIcon className="tel_icon" />
                  <strong>511 435 200</strong>
              </a>
            </div>

            <div className="indent-bottom">
              <div className="border-bottom">
                {/*change to normal jpg on click */}
                <h1>
                  <Link to="/">
                    Opony Tychy, Wulkanizacja Tychy, Wymiana opon Tychy
                  </Link>
                </h1>

                <nav className="navbar">
                  <ul className="menu responsive-menu">
                    <li>
                      <Link
                        to="/"
                        className="navItem"
                        activeClassName=" active-nav-item"
                      >
                        Start
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/opony-tychy"
                        aria-label="marki opon które sprzedajemy"
                        className="navItem"
                        activeClassName=" active-nav-item"
                        partiallyActive={true}
                      >
                        Opony
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/galeria"
                        aria-label="galeria naszego przedsiębiorstwa"
                        className="navItem"
                        activeClassName=" active-nav-item"
                        partiallyActive={true}
                      >
                        Galeria
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/kalkulator-opon"
                        aria-label="kalkulator zamienników opon"
                        className="navItem"
                        activeClassName=" active-nav-item"
                        partiallyActive={true}
                      >
                        Kalkulator
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/oferta"
                        aria-label="usługi jakie wulkanizacja Kostaauto świadczy"
                        className="navItem"
                        activeClassName=" active-nav-item"
                        partiallyActive={true}
                      >
                        Oferta
                      </Link>
                    </li>
                    <li className="last-item">
                      <Link
                        to="/kontakt"
                        aria-label="link do informacji kontaktowych i formularz kontaktowy"
                        className="navItem"
                        activeClassName=" active-nav-item"
                        partiallyActive={true}
                      >
                        Kontakt
                      </Link>
                    </li>
                  </ul>
                </nav>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
